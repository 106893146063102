import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import IconDirektsaft from "../../images/icons/direktsaft.png"
import IconMild from "../../images/icons/mild.png"
import IconVegan from "../../images/icons/vegan.png"
import IconBio from "../../images/icons/bio.png"

const ProductQualities = ({ block }) => {
  const qualityTypes = {
    direktaft: { icon: IconDirektsaft, text: "Direktsaft" },
    mild: { icon: IconMild, text: "Mild" },
    vegan: { icon: IconVegan, text: "Vegan" },
    bio: { icon: IconBio, text: "Bio" },
  }

  const bgTypes = {
    "green-paper": "bg-rapps-green-dark used-mask-y-1 bg-paper-mix",
    "blue-paper": "bg-rapps-blue-dark used-mask-y-1 bg-paper-mix",
    "purple-paper": "bg-rapps-purple-dark used-mask-y-1 bg-paper-mix",
    "red-paper": "bg-rapps-red-dark used-mask-y-1 bg-paper-mix",
    "orange-paper": "bg-rapps-orange-dark used-mask-y-1 bg-paper-mix",
    "yellow-paper": "bg-rapps-yellow-dark used-mask-y-1 bg-paper-mix",
    nature: "bg-paper used-mask-y-1",
  }

  /* +++++++++++++++++ Find third quality to show +++++++++++++++++ */

  let searchedquality2 = null
  var searchedqualities2 = ["mild", "direktaft", "bio"] // inverted to keep the last "best" hit
  for (let i = 0; i < searchedqualities2.length; i++) {
    if (block.qualities.indexOf(searchedqualities2[i]) != -1) {
      searchedquality2 = searchedqualities2[i]
    }
  }

  let searchedquality1 = null
  var searchedqualities1 = ["vegan", "mild", "direktaft"] // inverted to keep the last "best" hit
  for (let i = 0; i < searchedqualities1.length; i++) {
    if (
      block.qualities.indexOf(searchedqualities1[i]) != -1 &&
      searchedqualities1[i] !== searchedquality2
    ) {
      searchedquality1 = searchedqualities1[i]
    }
  }

  let bgImage =
    block.background_img?.filename?.length > 0
      ? block.background_img.filename
      : ""

  return (
    <>
      {(searchedquality1 !== null ||
        searchedquality2 !== null ||
        block.fruitcontent?.length < 0) && (
        <section className={`-mt-4 ${bgTypes[block.background_color]}`}>
          <div
            className="bg-center bg-cover"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div className="py-block block-w-container">
              <div className="flex flex-row flex-wrap justify-center pt-4 -mx-grid">
                {block.fruitcontent?.length > 0 && (
                  <div className="flex items-end justify-center w-full mb-10 sm:w-1/2 px-grid lg:w-1/3 ">
                    <span className="text-center text-white font-headline drop-shadow-svg">
                      <span className="block -mb-6 leading-none text-8xl md:text-9xl">
                        {block.fruitcontent}%
                      </span>
                      <span className="block mt-8 text-4xl md:text-6xl md:mt-10">
                        Fruchtgehalt
                      </span>
                    </span>
                  </div>
                )}
                {searchedquality1 && (
                  <div className="flex w-full mb-10 sm:w-1/2 px-grid lg:w-1/3">
                    <div className="flex flex-col items-center justify-end mx-auto text-center text-white font-headline ">
                      <span className="block w-full h-full mx-auto max-w-[12rem] max-h-[10rem] md:max-w-[18rem] md:max-h-[16rem]">
                        <Image
                          className="object-contain object-bottom w-full h-full drop-shadow-svg"
                          image={qualityTypes[searchedquality1]?.icon}
                        />
                      </span>
                      <span className="block mt-4 text-4xl drop-shadow-svg md:text-6xl md:mt-10">
                        {qualityTypes[searchedquality1]?.text}
                      </span>
                    </div>
                  </div>
                )}
                {searchedquality2 && (
                  <div className="flex w-full mb-10 sm:w-1/2 px-grid lg:w-1/3">
                    <div className="flex flex-col items-center justify-end mx-auto text-center text-white font-headline ">
                      <span className="block w-full h-full mx-auto max-w-[12rem] max-h-[10rem] md:max-w-[18rem] md:max-h-[16rem]">
                        <Image
                          className="object-contain object-bottom w-full h-full drop-shadow-svg"
                          image={qualityTypes[searchedquality2]?.icon}
                        />
                      </span>
                      <span className="block mt-4 text-4xl drop-shadow-svg md:text-6xl md:mt-10">
                        {qualityTypes[searchedquality2]?.text}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ProductQualities
