import React, { useState, useRef, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import ArrowBorder from "../atoms/ArrowBorder"

const ProductDetails = ({ block }) => {
  const [activeBottle, setActiveBottle] = useState(0)
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(true)
  const [ingredientsIsOpen, setIngredientsIsOpen] = useState(true)
  const refDescription = useRef(null)
  const refIngredients = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      setDescriptionIsOpen(false)
      setIngredientsIsOpen(false)
    }, 1000)
  }, [])
  if (block.product_details?.length > 0) {
    return (
      <BlockWrapper
        className="-mt-4 text-rapps-gray"
        blockWidth="container"
        block={block}
      >
        <div className="flex flex-row flex-wrap mt-8 -mx-grid">
          {/* Linke Seite */}
          <div className="w-full md:w-1/2 px-grid">
            {/* Flaschenwahl */}
            <span className="block mb-4 text-xl text-primary">
              Flaschengröße
            </span>
            <div className="flex flex-row flex-wrap mb-8 -mx-grid">
              {block.product_details?.map((bottle, index) => (
                <div key={index} className="w-1/3 px-grid">
                  <button
                    className={`w-full p-4 text-center used-border-bg-white used-border-bg-white-hover ${
                      index === activeBottle
                        ? "used-border-bg-white-active"
                        : ""
                    }`}
                    onClick={() => setActiveBottle(index)}
                  >
                    <Image
                      className="object-contain w-full mb-2 h-28"
                      image={bottle?.main_bundle?.url}
                      forceLoad="true"
                    />
                    <span
                      className={`text-lg ${
                        index === activeBottle ? "text-primary" : ""
                      }`}
                    >
                      {bottle.gebindegroesse_name}
                    </span>
                  </button>
                </div>
              ))}
            </div>
            {block.product_details[0].sku === "BIOTOM" ? (
              <div className="mb-8 -mt-4 text-sm">
                Bitte beachten Sie, dass es sich bei der 0,2 Flasche nicht um
                ein Bio-Produkt handelt.
              </div>
            ) : (
              ""
            )}
            <div>
              {/* Akkordeon Beschreibung */}
              <button
                className="flex w-full mb-4 text-xl text-primary"
                onClick={() =>
                  setDescriptionIsOpen(descriptionIsOpen ? false : true)
                }
              >
                Produktbeschreibung
                <ArrowBorder
                  isActive={descriptionIsOpen}
                  className={`inline-block text-white w-6 h-6 mt-0.5 ml-auto -mb-0.5 transform  ${
                    descriptionIsOpen === true ? "-rotate-90" : "rotate-90"
                  }`}
                />
              </button>
              <div
                className={`overflow-hidden transition-all duration-500`}
                ref={refDescription}
                style={{
                  height: `${
                    descriptionIsOpen
                      ? `${refDescription?.current?.scrollHeight}px`
                      : "0px"
                  }`,
                }}
              >
                <span className="block">
                  Flasche:{" "}
                  {`${block.product_details[activeBottle].gebindegroesse_name}
                  ${block.product_details[activeBottle].flasche}`}
                </span>
                <span className="block">
                  Verpackung: {block.product_details[activeBottle].verpackung}
                </span>
                {block.product_details[activeBottle]?.verkehrsbezeichnung
                  ?.length > 0 && (
                  <span
                    className="block mt-4"
                    dangerouslySetInnerHTML={{
                      __html: block.product_details[
                        activeBottle
                      ].verkehrsbezeichnung
                        .replace("Fruchtgehalt", "<br/>Fruchtgehalt")
                        .replace("Saftgehalt", "<br/>Saftgehalt"),
                    }}
                  ></span>
                )}
                {block.product_details[activeBottle]?.sonstige_produktaussagen
                  ?.length > 0 && (
                  <span className="block mt-4">
                    {
                      block.product_details[activeBottle]
                        .sonstige_produktaussagen
                    }
                  </span>
                )}
                {block.product_details[activeBottle]?.alcoholic_strength
                  ?.length > 0 && (
                  <span className="block mt-4">
                    Alkoholgehalt:{" "}
                    {block.product_details[activeBottle].alcoholic_strength}%
                  </span>
                )}
              </div>
            </div>
            {block.product_details[activeBottle].zutaten?.length > 0 && (
              <div className="mt-8">
                {/* Akkordeon Zusammensetzung */}
                <button
                  className="flex w-full mb-4 text-xl text-primary"
                  onClick={() =>
                    setIngredientsIsOpen(ingredientsIsOpen ? false : true)
                  }
                >
                  Zusammensetzung
                  <ArrowBorder
                    isActive={ingredientsIsOpen}
                    className={`inline-block text-white w-6 h-6 mt-0.5 ml-auto -mb-0.5 transform  ${
                      ingredientsIsOpen === true ? "-rotate-90" : "rotate-90"
                    }`}
                  />
                </button>
                <div
                  className={`overflow-hidden transition-all duration-500`}
                  ref={refIngredients}
                  style={{
                    height: `${
                      ingredientsIsOpen
                        ? `${refIngredients?.current?.scrollHeight}px`
                        : "0px"
                    }`,
                  }}
                >
                  <span>
                    {block.product_details[activeBottle].zutaten.map(
                      (zutat, index) => (
                        <React.Fragment key={index}>
                          {zutat}
                          {index + 1 !==
                          block.product_details[activeBottle].zutaten.length
                            ? ", "
                            : ""}
                        </React.Fragment>
                      )
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* Rechte Seite */}
          <div className="w-full md:w-1/2 px-grid">
            {/* Nährwerte-Tabelle */}
            <span className="block mb-4 text-xl text-primary">Nährwerte</span>
            <table className="w-full table-auto">
              <thead>
                <tr className="font-bold border-b border-black">
                  <td className="pb-2">Energie/Nährstoffe</td>
                  <td className="pb-2 text-right">Gehalt*</td>
                </tr>
              </thead>
              <tbody>
                {block.product_details[activeBottle].naehrwerte.map(
                  (wert, index) => (
                    <tr key={`tr-${index}`} className="border-b border-black">
                      <td className="py-2">{wert.bezeichnung}</td>
                      <td className="py-2 text-right">{wert.menge}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <span className="block mt-4">* Nährwertangaben je 100ml</span>
            <span className="block">
              ** Anteil des empfohlenen Tagesbedarfs
            </span>
          </div>
        </div>
      </BlockWrapper>
    )
  } else {
    return null
  }
}

export default ProductDetails
