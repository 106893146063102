import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import ProductHero from "../../src/components/modules/ProductHero"
import ProductDetails from "../../src/components/modules/ProductDetails"
import ProductCards from "../../src/components/modules/ProductCards"
import ProductQualities from "../../src/components/modules/ProductQualities"
import ScrollIndicator from "../../src/@yumgmbh/gatsby-theme-yum-components/components/modules/ScrollIndicator"

const ProductPage = ({
  content,
  breadcrumbs,
  mainArticle,
  additionalArticles,
  similarProducts,
}) => {
  return (
    <Layout>
      <SEO
        content={content}
        description={content.intro_text}
        og_image={content?.fruit_background?.filename}
      />

      <ProductHero
        block={{
          background_color: content.background_color,
          headline: content.headline,
          subheadline: content.subheadline,
          intro_text: content.intro_text,
          image: mainArticle?.main_bundle?.url,
        }}
      />
      <ScrollIndicator lightVersion="true" />
      <ProductQualities
        block={{
          background_img: content.fruit_background,
          background_color: content.background_color,
          qualities: content.qualities,
          fruitcontent: content.fruit_content,
        }}
      />
      <BodyBlocks body={content.body} />
      <ProductDetails
        block={{
          background_color: content.background_color,
          headline: "Das steckt drin",
          product_details: additionalArticles,
        }}
      />

      <ProductCards
        block={{
          headline: "Schon probiert?",
          subheadline: "Weitere leckere Sorten",
          view: "slider",
          card_items: similarProducts,
        }}
      />

      {/* <div className="p-4 overflow-x-scroll text-sm bg-gray-100">
        <br />
        <h3>Page Content</h3>
        <pre>{JSON.stringify(content, null, 2)}</pre>

        <br />
        <br />
        <h3>Main article data</h3>
        <pre>{JSON.stringify(mainArticle, null, 2)}</pre>

        <br />
        <br />
        <h3>Additional articles data</h3>
        <pre>{JSON.stringify(additionalArticles, null, 2)}</pre>

        <br />
        <br />
        <h3>Similar Products</h3>
        <pre>{JSON.stringify(similarProducts, null, 2)}</pre>
      </div> */}
    </Layout>
  )
}

export default ProductPage
