import React from "react"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const ProductHero = ({ block }) => {
  if (block.image === undefined) {
    block.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  const bgTypes = {
    "green-paper": "bg-rapps-green-dark used-mask-y-1 bg-paper-mix",
    "blue-paper": "bg-rapps-blue-dark used-mask-y-1 bg-paper-mix",
    "purple-paper": "bg-rapps-purple-dark used-mask-y-1 bg-paper-mix",
    "red-paper": "bg-rapps-red-dark used-mask-y-1 bg-paper-mix",
    "orange-paper": "bg-rapps-orange-dark used-mask-y-1 bg-paper-mix",
    "yellow-paper": "bg-rapps-yellow-dark used-mask-y-1 bg-paper-mix",
    nature: "bg-paper used-mask-y-1",
  }

  return (
    <section className="">
      <div className="flex flex-col justify-between h-hero-product">
        <div className="block-w-container pt-block">
          <div className="grid grid-cols-6 gap-8 xauto-rows-fr auto-rows-auto">
            <div className="col-span-6 sm:col-span-4 md:col-span-3">
              <HeadlineEnhanced
                headline={block.headline}
                subheadline={block.subheadline}
                tagName="h1"
              />
              <Richtext
                className="mt-2 mb-4 text-secondary"
                text={block.intro_text}
              />
            </div>
            <div className="relative flex align-bottom min-h-[50vh] md:min-h-fit z-10 col-span-6 sm:col-span-2 md:col-span-3 ">
              <div
                className={`${
                  bgTypes[block.background_color]
                } absolute bottom-0 -left-8 -right-8 h-[25vh] sm:hidden`}
              ></div>
              <Image
                className=" absolute object-contain w-full h-[45vh] max-h-[50vh] sm:max-h-fit sm:h-[75vh] max-w-full md:transform md:-translate-x-1/2 md:left-1/2"
                image={block.image}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            bgTypes[block.background_color]
          } hidden sm:block h-full max-h-[25vh] sm:max-h-[50vh]`}
        ></div>
      </div>
    </section>
  )
}

export default ProductHero
