import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import arrowBg from "../../../../images/design/arrow-bg.svg"
import arrowBgWhite from "../../../../images/design/arrow-bg-white.svg"

const ScrollIndicator = ({ lightVersion = false }) => {
  return (
    <section className="relative scroll-indicator" id="target">
      <div className="absolute z-30 hidden transform -translate-x-1/2 md:block bottom-full left-1/2">
        <div className="mb-4">
          <a href="#target">
            <span
              className={`relative  inline-block mb-2 w-12 h-12 transform rotate-90`}
            >
              {lightVersion ? (
                <Image image={arrowBgWhite} />
              ) : (
                <Image image={arrowBg} />
              )}
            </span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default ScrollIndicator
