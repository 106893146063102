import React from "react"
import { graphql } from "gatsby"
import ProductPage from "./ProductPage"

const ProductPageTemplate = ({ data, pageContext }) => {
  const content = JSON.parse(pageContext.content)
  const mainArticle = data.main_article
  let additionalArticles = []
  if (mainArticle) {
    additionalArticles.push(mainArticle)
  }
  additionalArticles.push(...data.additional_articles?.nodes)

  const similarProducts = pageContext.similar_products

  const subNavItems = pageContext.subNavData.map((subNavItem) => ({
    link: subNavItem.real_path,
    link_text: subNavItem.name,
  }))

  const breadcrumbs = pageContext.breadcrumbs
    .map((item) => ({ link: item.real_path, link_text: item.name }))
    ?.reverse()

  return (
    <ProductPage
      content={content}
      breadcrumbs={breadcrumbs}
      mainArticle={mainArticle}
      additionalArticles={additionalArticles}
      similarProducts={similarProducts}
    />
  )
}

export const query = graphql`
  query ProductPageTemplateQuery(
    $main_sku: String
    $additional_skus: [String]
  ) {
    main_article: mediaArticle(sku: { eq: $main_sku }) {
      id
      sku
      gebindegroesse
      gebindegroesse_name
      verpackung
      flasche
      verkehrsbezeichnung
      ernaehrungsweisen
      zusatzstoffe
      zutaten
      allergene {
        id
      }
      naehrwerte {
        bezeichnung
        rda
        menge
      }
      main_bundle {
        url
      }
      alcoholic_strength
      sonstige_produktaussagen
    }
    additional_articles: allMediaArticle(
      filter: { sku: { in: $additional_skus } }
    ) {
      nodes {
        id
        sku
        gebindegroesse
        gebindegroesse_name
        verpackung
        flasche
        verkehrsbezeichnung
        ernaehrungsweisen
        zusatzstoffe
        zutaten
        allergene {
          id
        }
        naehrwerte {
          bezeichnung
          rda
          menge
        }
        main_bundle {
          url
        }
        alcoholic_strength
        sonstige_produktaussagen
      }
    }
  }
`
export default ProductPageTemplate
